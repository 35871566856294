@import public/sass/abstracts

.submenu
    display: none
    z-index: 9999
    background: #fff
    position: fixed
    top: $header-height
    left: 0
    right: 0
    margin: auto
    width: 100vw
    +box-shadow(0px 4px 4px rgba(0, 0, 0, 0.1))
    &--open
      display: block
    &__container
      @extend .w-container  
      padding: 30px 0 40px 0
      display: flex
      justify-content: space-between 
      width: 100%
      overflow: hidden
      @media (max-width: 1280px + 32px)
        padding: 30px $wcont-padding 40px $wcont-padding
      &--simple-flow
          justify-content: flex-start
    &__list
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      &__item
        margin-right: $wcont-padding
        &:last-child
          margin-right: $wcont-padding
          // margin-left: auto
        &__leaves
          margin-top: 4px
          .terzo-desktop 
            margin-top: 16px
            &--see-all
              color: $color-primary
          &__formazione-viva
            display: flex

      &--horizontal-flow
          .submenu
              &__list
                  &__item
                    &:not(:last-child)  
                        +res-lg-min
                          margin-right: $wcont-padding
                    &:last-child
                        margin-right: 0
                        margin-left: 0
                    .secondo-desktop, .terzo-desktop 
                      width: 284px
                      max-width: 284px
                    &.formazione-viva
                      .secondo-desktop, .terzo-desktop
                        width: 233px
                        max-width: 233px
      &--libri
        +res-lg-min
          flex-direction: column
          max-height: 380px
          justify-content: flex-start
          .submenu
              &__list
                  &__item
                    &:not(:last-child)
                      margin-bottom: 32px
                    .secondo-desktop, .terzo-desktop 
                      width: 23rem

    &__tiles
      display: flex
      &--riviste
        flex-wrap: wrap
        width: calc( 200px * 2 + #{$wcont-padding} )
      &--catalogo
        width: 240px
        margin-right: 16px
      &--numeri-riviste
        max-width: 848px
      &__rivista
        display: flex
        align-items: center
        justify-content: center
        width: 182px
        padding: 20px 30px
        border: 1px solid $grey-1
        +res-xl-min
          width: 200px
        +border-radius(6px)
        &:nth-child(odd)
          margin-right: $wcont-padding
        &:nth-child(-n+2)
          margin-bottom: $wcont-padding
      &__text
        display: flex
        align-items: center
        width: 240px
        // height: 84px
        max-height: 97px
        padding: 12px
        border: 1px solid $grey-1
        +border-radius(6px)
        &:nth-child(odd)
          margin-bottom: $wcont-padding
        &__text
          +font(14, 20, 700)
          &:not(:first-child)
            margin-left: 12px
          &:first-child
            margin: auto
      &__numero-rivista,
      &__shop
        display: flex
        align-items: center
        background: $grey-0
        padding: 20px
        height: 200px
        +border-radius(6px)
        &:not(:last-child)
          margin-right: $wcont-padding
        div:first-child
          +res-lg-max
            display: none!important // sovrascrive next/image
        &__content
          margin-left: 20px
          +res-lg-max
            margin-left: 0
        &__title
          +font(18, 24, 800)
          margin-bottom: 12px
        &__description
          +font(14, 16, 500)
          margin-bottom: 12px
      &__libera
        display: flex
        align-items: center
        justify-content: center
        width: 200px
        padding: 10px 30px
        border: 1px solid $grey-1
        max-height: 100px
        +border-radius(6px)
        &:not(:last-child)
          margin-right: $wcont-padding
        +res-md-max
          width: 255px
          margin-bottom: 10px
      .menu-tile
        &:not(:last-child)
          margin-right: $wcont-padding
    &__voci-libere
      &--tiles
        display: flex
        +res-md-max
          flex-direction: column
          margin-top: 32px
      .terzo-desktop 
        &:not(:last-child)
          margin-bottom: $wcont-padding
          +res-md-max
            margin-bottom: 32px
    +res-md-max
      position: relative
      width: 100%
      top: 0
      // padding: 0 19px
      +box-shadow(none)
      .submenu
        &__container
          flex-direction: column
          padding: 0 0 40px 0
        &__list
          &__item
            width: 100%
            max-width: 100%
            overflow: hidden
            border: 1px solid $grey-1
            +border-radius(12px)
            margin-bottom: 12px
            margin-right: 0
            // &:not(:last-child)
            //   border-bottom: 1px solid $grey-2
            .terzo-desktop
              margin-top: 20px
              padding-left: 20px
              &:not(:last-child)
                margin-bottom: 20px
            &__leaves
              margin-top: 0
        &__tiles
          flex-direction: column
          &__rivista
            width: 255px
            margin-bottom: 10px
          &__numero-rivista
            flex-direction: column
            width: 255px
            height: auto
            margin-bottom: 10px
            &__title
              margin-top: 20px
          .menu-tile
            width: 100%
            margin-bottom: 16px
            margin-right: 0
            &--shop
              flex-direction: column
